<template>
    <div class="myAnswer">
        <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane label="我的问答"></el-tab-pane>
        </el-tabs>
        <el-collapse v-model="collActive" @change="handleChange">
            <el-collapse-item :name="index" v-for="(item,index) in dataList" :key="index">
                <template slot="title">
                    <!-- <div class="list-title">
                        <p>{{item.questionName}}</p>
                        <p>123</p>
                    </div>-->
                    <div class="conter_header">
                        <p style="width:90%;">
                            <img src="../../assets/mySelf/my-ask.png" alt />
                            <span>{{ item.questionName }}</span>
                        </p>

                        <p style="font-size:14px;cursor:pointer;" v-if="item.answerUserId !=null">已解答</p>
                        <p style="font-size:14px;background:#CC1F3C;cursor:pointer;" v-if="item.answerUserId ==null || item.answerUserId==''">待解答</p>
                    </div>
                </template>
                <div class="list-conter" v-for="(item2,index2) in item.childList" :key="index2">
                    <div class="conter_text" v-html="item2.questionDescribe"></div>
                    <div class="conter_imgList">
                        <img v-if="item3" :src="item3" alt v-for="(item3,index3) in item2.questionPics" :key="index3" />
                    </div>
                    <div class="conter_title">
                        <p>
                            <span>提问时间：{{ item2.releaseTime }}</span>
                        </p>
                        <!-- <p>{{item.intime}}</p> -->
                    </div>
                    <el-divider v-if="item.answerUserId !=null"></el-divider>
                    <!-- 回答 -->
                    <div class="answer_header" v-if="item.answerUserId !=null">
                        <img :src="item.answerUserHeadPic" alt />
                        <p>{{ item2.answerUserNickName }}</p>
                    </div>
                    <div class="conter_text" v-html="item2.answerContent" v-if="item.answerUserId !=null"></div>
                     <div class="conter_imgList" v-if="item.answerUserId !=null">
                        <img :src="item3" alt v-for="(item3,index3) in item2.answerPics" :key="index3" />
                    </div>
                    <div class="conter_title" v-if="item.answerUserId !=null">
                        <p>
                            <span>回答时间：{{ item2.answerTime }}</span>
                        </p>
                        <!-- <p>{{item.intime}}</p> -->
                    </div>
                </div>
            </el-collapse-item>
            <div class="" v-if="dataList.length">
                <el-pagination layout="prev, pager, next"  :total="count" @current-change="currentChange"></el-pagination>
            </div>
            <div class="noMore" v-if="dataList.length ==0">
                <div class="coursediv_empty_img">
                    <img src="@/assets/class_empty.png" alt="">
                </div>
                暂无数据
            </div>
        </el-collapse>
        

    </div>
        
</template>

<script>
import { wd_list, detail_list } from '../../api/myself';
export default {
    name: 'myAnswer',
    data() {
        return {
            active: 0,
            collActive: [],
            collList: [],
            dataList: [],
            page:0,
            count:0,

        }
    },
    mounted() {
        this.wd_list();
    },
    methods: {
        currentChange(page){
            this.page = page-1;
            this.wd_list();
        },
        handleClick() { },
        wd_list() {
            wd_list({ type: 1,page:this.page }).then(res => {
                console.log(res);
                this.count = res.data.count;
                res.data.data.forEach(item => {
                    item.isShow = false;
                    item.childList = [];
                });
                this.dataList = res.data.data;
            })
        },
        // 二级展示
        handleChange() {
            if (this.collActive.length > this.collList.length) {
                let collActive = this.collActive;
                let collList = this.collList;
                let idx = 0;
                collActive.forEach(item => {
                    if (!collList.includes(item)) {
                        idx = item;
                    }
                })
                if (!this.collList.length) {
                    idx = collActive[0]
                }
                console.log(idx);
                let dataList = this.dataList;
                dataList[idx].isShow = !dataList[idx].isShow;
                if (dataList[idx].isShow) {
                    this.detail_list(dataList[idx].wdId, idx);
                }

                console.log(this.collActive);
                console.log(this.collList);
            }
            this.collList = this.collActive;
        },
        detail_list(id, idx) {
            let dataList = this.dataList;
            detail_list({ wdId: id }).then(res => {
                console.log(res.data.data);
                dataList[idx].child = res.data.data;
                dataList[idx].childList = res.data.data.wdQuestionAnswerDetailList;
            })
        },
        test() {
            console.log(2);
        }

    }
}
</script>

<style scoped lang="scss">
.myAnswer {
    .list-title {
        display: flex;
        justify-content: space-between;
        flex: auto;
        margin: 0 20px;
    }
    .list-conter {
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(204, 204, 204, 1);
        padding: 20px;
    }
    .conter_header {
        padding: 0 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p:nth-child(1) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
                width: 31px;
                height: 31px;
            }
            span {
                font-size: 16px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                // font-weight: bold;
                color: #333333;
                margin-left: 10px;
                text-align:left;
                line-height:24px;
                width:80%;
            }
        }
        p:nth-child(2) {
            width: 90px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            background: #67b364;
            border-radius: 24px;
        }
    }
    .conter_text {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #555555;
        // margin-top: 25px;
        text-align: left;
    }
    .answer_header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
            width: 31px;
            height: 31px;
        }
        p {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            margin-left: 10px;
        }
    }
    .conter_imgList {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            width: 80px;
            height: 80px;
            margin-right: 10px;
        }
        margin-top: 20px;
    }
    .conter_title {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 20px;
        p:nth-child(1) {
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            // span:nth-child(1) {
            //     font-size: 16px;
            //     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            //     font-weight: 400;
            //     color: rgba(153, 153, 153, 1);
            // }
            // span:nth-child(2) {
            //     font-size: 16px;
            //     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            //     font-weight: 400;
            //     color: rgba(102, 102, 102, 1);
            // }
        }
        p:nth-child(2) {
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-top: 10px;
        }
        p:nth-child(3) {
            padding: 0 2px;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #5f7cfd;
            border-bottom: 1px solid #5f7cfd;
        }
    }
}
</style>